@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&family=Open+Sans:wght@300;400;700&display=swap');
@import './colors.scss';
@import './animation.scss';

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'info': $third
);

@import './../../../node_modules/bootstrap/scss/bootstrap.scss';

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite !important;
}

* {
  font-family: 'Roboto', 'Open Sans', sans-serif;
  list-style: none;
  font-size: 14px;
}

body {
  background: linear-gradient(180deg, $primary, $secondary);
  background-attachment: fixed;
}

ul {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100vh !important;
}

.swal2-styled.swal2-confirm {
  background-color: $primary !important;
}

.swal2-loader {
  border-color: $primary transparent $primary transparent !important;
}

.swal2-icon.swal2-info {
  border-color: $secondary !important;
  color: $secondary !important;
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  border: 1px solid $secondary !important;
  box-shadow: 0 0 0 3px $primary !important;
}

.boxDash {
  border: $primary 1px dashed;
  margin: 0.5em;
  border-radius: 10px;
  text-align: center;
  padding: 15px 25px;
}

.boxDash .label {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: $primary;
}

.boxDash .value {
  font-size: 47px;
  font-weight: bold;
  color: $black;
}

table tr td {
  font-size: 12px;
}

table tr th {
  font-size: 12px;
}

.table-cartorio > thead > tr > th:nth-child(2) {
  width: 100px;
}

.table-cartorio > thead > tr > th:nth-child(3) {
  width: 200px;
}

.table-cartorio > thead > tr > th:nth-child(4) {
  width: 350px;
}

table thead {
  backdrop-filter: blur(4px);
  background: #ffffff;
}

table thead th {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  // text-align: center;
}

table tbody td {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #00d6b126 !important;
}

.table-cartorio {
  margin: 0 5px;
}

.table-horizontal-scroll {
  table-layout: auto !important;
}

.table-max-width {
  max-width: calc(100vw - 123px);
}

.page-subtitle {
  padding-bottom: 10px;
  margin-top: 0px;
  color: $primary;
}

.selectCNPJ {
  font-size: 25px;
  margin-top: 0px !important;
  padding: 0;
  height: 44px;
  width: 250px;
  background: none;
  border: 2px solid #00000021;
  color: black;
  padding-left: 10px;
}

.selectCNPJ-PJ {
  width: 80%;
  font-size: 15px;
}

.selectLabel {
  margin-top: 8px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
}

.btnFilter {
  text-transform: uppercase;
  font-weight: 700;
}

.btnSearchSelect {
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnSearchSelect span {
  margin-left: 10px;
}

.swal2-image {
  animation: rotation 2s linear infinite;
}

.btnWindowHide {
  margin: 5px;
}

.jive-section {
  font-size: 10px;
  text-transform: uppercase;
  border: 2px solid $primary;
  padding: 6px;
  display: inline-block;
  border-radius: 5px;
  color: $primary;
  font-weight: 700;
  margin-bottom: 10px;
}

.jive-separator-v {
  border-right: 1px solid $primary;
  width: 33%;
}

.itemMatch {
  background: $primary;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  display: inline-block;
  margin-left: 0;
}

.itemMatchRoot {
  margin-bottom: 10px;
  border-bottom: 1px solid $primary;
}

.itemCNPJ {
  font-size: 12px;
  font-weight: 500;
}

.itemMatchContent {
  margin-left: 20px;
  border-left: 1px solid $primary;
  padding-left: 10px;
}

.noRecordGrafo {
  font-size: 2em;
  font-weight: 100;
  color: #0000002b;
  line-height: 1em;
  padding: 1em;
  margin-top: 1em;
}

.no-data {
  background: black;
  color: white;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px;
  display: table;
}

.titleDividas {
  background: white;
  text-align: left;
  padding: 7px;
  text-transform: uppercase;
  font-weight: 700;
}

.tableDividas thead tr th {
  text-transform: uppercase;
  font-size: 10px;
}

.blocoOcorrencia {
  text-align: center;
}

.titleDivida {
  font-size: 1.5em;
  font-weight: 300;
  margin-bottom: 5px;
}

.dividaTxt {
  color: #8bc745;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
}

.dividaIconSuccess {
  background: #8bc745;
  border-radius: 50%;
  padding: 10px;
  width: 80px;
  height: 80px;
  padding-top: 15px;
}

.dividaIconError {
  background: red;
  border-radius: 50%;
  padding: 10px;
  width: 80px;
  height: 80px;
  padding-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

* {
  scrollbar-width: thin;
}

.d-grid {
  display: grid !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

input[type='radio'] {
  accent-color: #2c9e69;
}
