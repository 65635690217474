.container {
  margin-left: 55px;
}

.header {
  color: #2c9e69;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}

.input {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
}

.input input {
  box-shadow: none;
  border: 0px;
  height: 36px;
  padding: 6px;
}

.input button {
  border: 0px;
}
