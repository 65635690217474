@import './colors.scss';

.regCard {
  background: white;
  width: 400px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
}

.regCardBody {
  padding: 0 0 5px 0;
  margin: 0px 0 0 0px;
  border-left: 2px solid $primary;
  border-bottom: 1px solid $primary;
  padding-bottom: 10px;
}

.regCardCartorio {
  font-size: 16px !important;
  font-weight: 500;
}

.regCardLabel {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: $primary;
}

.regCardItem {
  margin: 8px 0 0;
}

.regCardValue {
  font-size: 12px;
  text-transform: uppercase;
}

.btnPartes {
  margin-left: 10px;
  margin-top: 10px;
  font-size: 12px;
}

.regCardPartesRoot {
  background: #e6e6e6;
  margin: 10px;
  border-radius: 7px;
  padding: 5px;
  font-size: 10px;
  line-height: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 100%;
  clear: both;
}

.partesRoot {
  width: 100%;
}

.parteItem {
  margin-bottom: 10px;
  display: flex;
}

.regCardPartesRoot label {
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 20px;
}
.regCardPartesRoot .parteValue {
  text-transform: uppercase;
}
