.divisor {
  background-color: #2c9e69;
  height: 7px;
}

.container {
  background: #002a40;
}

.powered {
  color: #8a8b8c;
  line-height: 1.2rem;
}

.version {
  color: #ffffff;
  font-size: 14px;
}
